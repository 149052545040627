<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h5 primary--text"><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->{{form.car_plate}} {{form.province}} งานเสร็จแล้ว</v-card-title>
    <v-card-text class="popup-background">

      
      <v-row justify="center" class="pt-5" dense>
        <v-col cols="8">
          <v-text-field
            v-model="form.receiver_name"
            label="ชื่อผู้มารับเอกสาร"
            outlined
            dense
            background-color="white"
            :error-messages="$v.form.receiver_name.$error ? $error_text : ''"
          />
          <DatePicker 
            :value="form.pick_up_date" 
            label="วันที่มารับ" 
            @onChange="(val) => (form.pick_up_date = val)" 
            :customAllowedDates="[0,6]" 
            outlined dense background-color="white" 
            :error-messages="$v.form.pick_up_date.$error ? $error_text : ''"
          />
          <TimePicker 
            :value="form.pick_up_time" 
            label="เวลาที่มารับ" 
            @onChange="(val) => (form.pick_up_time = val)"
            outlined dense background-color="white" 
            :error-messages="$v.form.pick_up_time.$error ? $error_text : ''"
          />
          <v-text-field
            v-model="form.parcel_delivery"
            label="จัดส่งพัสดุ"
            outlined
            dense
            background-color="white"
            :error-messages="$v.form.pick_up_time.$error ? $error_text : ''"
          />
          <v-text-field
            v-model="form.delivery_number"
            label="เลขพัสดุ"
            outlined
            dense
            background-color="white"
            :error-messages="$v.form.pick_up_time.$error ? $error_text : ''"
          />
          <v-textarea
            v-model="form.note"
            label="โน๊ต"
            outlined
            dense
            background-color="white"
            :error-messages="$v.form.pick_up_time.$error ? $error_text : ''"
          />
        </v-col>
      </v-row>


    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn class="px-10 mr-15" color="primary" @click="save()" :loading="loading" :disabled="loading">บันทึก</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import TimePicker from "@/components/TimePicker.vue";
import { required } from 'vuelidate/lib/validators';

import * as moment from "moment";
  export default {
    components: {
      DatePicker,
      TimePicker,
    },
    props: {
      item: {
        type: Object,
        default: null,
      },
    },
    validations: {
      form: {
        receiver_name: { required },
        pick_up_date: { required },
        pick_up_time: { required },
      },
    },
    data () {
      return {
        loading: false,
        form: {
          receiver_name: '',
          pick_up_date: moment().format("YYYY-MM-DD"),
          pick_up_time: moment().format("HH:mm"),
          parcel_delivery: '',
          delivery_number: '',
          note: '',
        }
      }
    },
    created() {
      this.form = this.item
      this.form.pick_up_date = moment().format("YYYY-MM-DD")
      this.form.pick_up_time = moment().format("HH:mm")
    },
    methods: {
      close() {
        this.$emit('onClose');
      },
      async save(){
        this.loading = true;
        let filter = {
          process_id: this.form._id,
          pick_up_date: this.form.pick_up_date,
          pick_up_time: this.form.pick_up_time,
          delivery_number: this.form.delivery_number,
          note: this.form.note
        }
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/process/record_transportation_parcel_book`, body)
          .then((res) =>{
            console.log(res)
          })
          .catch((err) => {
            console.log('err',err);
            this.$alertServerError({ title : err.error_message });
          });
        this.loading = false;
        // submit data here baby. 
        this.close();
      },
    },
    computed: {
      isUpdate() {
        return this.item;
      }
    },
  }
</script>

