<template>
    <div>
      <v-data-table
        class="elevation-2"
        :headers="headers"
        :items="list"
        :loading="loading"
        sort-by="calories"
        :loading-text="$table_loading_text"
        :page.sync="page"
        :items-per-page="filter.item_per_page"
        hide-default-footer
        show-select
        item-key="_id"
        v-model="selected"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{item.date | moment($formatDate)}}
        </template>
        <!-- <template v-slot:[`item.time`]="{ item }">
          {{moment(item.date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")}}
        </template> -->
        <template v-slot:[`item.transportation_product`]="{ item }">
          {{item.transport_data.transportation_product}}
        </template>
        <template v-slot:[`item.other_product`]="{ item }">
          {{item.transport_data.other_product }}
        </template>
        
        <template v-slot:footer>
          <table-pagination-custom
            :page="filter.page" 
            :itemsPerPage="filter.item_per_page" 
            :length="pageCount"
            @change-page="onChangePage"
            @change-items-per-page="onChangeitemsPerPage"
          />
        </template>

      </v-data-table>

    </div>

</template>

<script>

import * as moment from "moment";

export default {
  props: ['search'],
  data: () => ({
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    list: [],
    headers: [
      { text: 'วันที่', value: 'date', filterable: false },
      { text: 'เวลา', value: 'time', filterable: false },
      { text: 'ทะเบียน', value: 'car_plate', filterable: false },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'งานขนส่ง', value: 'transportation_product', filterable: false },
      { text: 'งานอื่น ๆ', value: 'other_product', filterable: false },
      { text: 'โน๊ต', value: 'note', filterable: false },
      { text: 'หมายเหตุ', value: 'reason_on_fail', filterable: false },
    ],

    isCar: true,
    filter: {
      branch_id: null,
      tracking_type: 2,
      tracking_status: 4,
      tax_renewal_round: '',
      car_type_filter: 0,
      item_per_page: 10,
      page: 1,
      start_with: ''
    }
  }),
  mounted () {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData () {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/process/get_process`, body)
        .then((res) =>{
          this.list = res.result.data;
          this.list.forEach((item, i) => {
            this.list[i].time = moment(item.date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm")
          });
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page){
      this.filter.page = page
      this.getData()
    },
    onChangeitemsPerPage(number){
      this.filter.item_per_page = number
      this.onChangePage(1)
    },

    cancel() {

    },
  },
   watch: {
    search(nv, ov) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (nv != ov) {
          this.filter.start_with = nv;
          this.getData();
        }
      }, 1000);
    },
  }
}
</script>