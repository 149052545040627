<template>
  <v-card class="py-10">
    <v-card-text class="popup-background">

      <v-row justify="center">
        <v-col cols="10">
          <div style="width: 400px">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="ค้นหา เลขทะเบียน ชื่อลูกค้า เบอร์โทร"
              outlined
              rounded
              dense
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="10">
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="list"
            :loading="loading"
            sort-by="index"
            :loading-text="$table_loading_text"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:[`item.index`]="{ item }">
              {{list.map(function(x) {return x._id; }).indexOf(item._id)+1}}
            </template>
            <template v-slot:[`item.col_e`]="{ item }">
                <v-btn color="primary" :outlined="item.col_e" small @click="selectDocuemnt(item)">เลือก</v-btn>
            </template>
            <template v-slot:footer>
                <table-pagination-custom
                  :page="page" 
                  :itemsPerPage="itemsPerPage" 
                  :length="pageCount"
                  @change-page="onChangePage"
                  @change-items-per-page="onChangeitemsPerPage"
                />
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="10" class="mt-5">
          <v-data-table
            class="elevation-1"
            :headers="table2Headers"
            :items="table2List"
            :loading="loading"
            sort-by="index"
            :loading-text="$table_loading_text"
            hide-default-footer
            show-select
            item-key="product_id"
            v-model="selected"
          >
            <template v-slot:[`item.index`]="{ item }">
              {{table2List.map(function(x) {return x.product_id; }).indexOf(item.product_id)+1}}
            </template>
          </v-data-table>
        </v-col>

      </v-row>

    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">ปิด</v-btn>
      <v-btn class="px-10 mr-15" color="primary" @click="save()" :loading="loading" :disabled="loading">ตกลง</v-btn>
    </v-card-actions>

  </v-card>
</template>

<script>
  export default {


    data () {
      return {
        search: '',
        loading: true,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        list: [],
        headers: [
            { text: 'ลำดับ', value: 'index'},
            { text: 'แลขที่ใบแจ้งหนี้/ใบเสร็จ', value: 'number', filterable: false },
            { text: 'ทะเบียน', value: 'car_plate', filterable: false},
            { text: 'จังหวัด', value: 'car_province', filterable: false},
            { text: '', value: 'col_e', filterable: false },
        ],
        select_docuemnt_id: "",
        selected: [],
        table2List: [],
        table2Headers: [
            { text: 'ลำดับ', value: 'index'},
            { text: 'รายชื่อสินค้า', value: 'name', filterable: false },
            { text: 'ราคา', value: 'price', filterable: false},
        ],
      }
    },

    created () {
      this.getData()
    },

    methods: {
      async getData () {
        this.loading = false;
        let filter = {
          start_with: this.search,
          page: this.page,
          item_per_page: this.itemsPerPage,
        }
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/process/search_document`, body)
          .then((res) =>{
            this.list = res.result.data;
            this.pageCount = res.result.total_page || 1;
          })
          .catch((err) => {
            console.log('err',err);
            this.$alertServerError({ title : err.error_message });
          });
        this.loading = false;
      },      
      onChangePage(page){
        this.page = page
        this.getData()
      },
      onChangeitemsPerPage(number){
        this.itemsPerPage = number
        this.onChangePage(1)
      },
      close() {
        this.$emit('onClose');
      },
      selectDocuemnt(item) {
        this.select_docuemnt_id = item._id
        this.table2List = item.product
        this.selected = []
      },
      async save(){
        this.loading = true;
        let products = []
        this.selected.forEach((item, i) => {
          products = products.concat({
            "document_id": this.select_docuemnt_id,
            "product_id": item.product_id,
            "price": item.price
          })
        });
        let body = {
          token: this.$jwt.sign({products: products}, this.$privateKey, { noTimestamp: true }),
        };
        await this.$axios.post(`${this.$baseUrl}/process/track_items`, body)
          .then((res) =>{
            this.close();
          })
          .catch((err) => {
            console.log('err',err);
            this.$alertServerError({ title : err.error_message });
          });
        this.loading = false;
      },
    },

    watch: {
      search : function (newVal, oldVal) {
        if(newVal != oldVal) {
          this.getData();
        }
      },
    }
  }
</script>

