<template>
  <div class="px-5">
    <v-alert
      type="info"
      shaped
      class="mb-3"
    >
      ระบบค้นหา และปุ่มงานสำเร็จ ใช้งานได้แล้วค่า
    </v-alert>
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">งานขนส่ง และอื่นๆ </div>
      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา เลขทะเบียน ชื่อลูกค้า เบอร์โทร"
          outlined
          rounded
          dense
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>

      <template v-if="tab === 0">
        <!-- <v-text-field label="วันที่" prepend-inner-icon="mdi-calendar" outlined rounded dense hide-details></v-text-field> -->
        <div style="width: 180px">
          <v-select
            label="ประเภทรถ"
            v-model="carTypeId"
            :items="[
              { text: 'ทั้งหมด', value: 0 },
              { text: 'รถยนต์', value: 1 },
              { text: 'รถจักรยานยนต์', value: 2 },
              { text: 'อื่น ๆ', value: 3 }
            ]"
            item-value="value"
            item-text="text"
            outlined
            rounded
            dense
            hide-details
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          small
          class="ml-5"
          @click="processPendingCancel()"
          :disabled="!selected.pending.length"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="success"
          small
          class="ml-2"
          @click="processTrasportationJob()"
          :disabled="!selected.pending.length"
        >
          กำลังดำเนินการ
        </v-btn>
        <!-- <v-btn color="primary" small class="ml-2" @click="add()">
          <v-icon left>mdi-plus</v-icon> เพิ่มติดตามงานอื่น ๆ
        </v-btn> -->
      </template>

      <template v-if="tab === 1">
        <div style="width: 180px">
        <v-select
            label="ประเภทรถ"
            v-model="carTypeId"
            :items="[
              { text: 'ทั้งหมด', value: 0 },
              { text: 'รถยนต์', value: 1 },
              { text: 'รถจักรยานยนต์', value: 2 },
              { text: 'อื่น ๆ', value: 3 }
            ]"
            item-value="value"
            item-text="text"
            outlined
            rounded
            dense
            hide-details
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          small
          class="ml-5"
          @click="$refs.inprogress.selected=[]"
          :disabled="!selected.inprogress.length"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="success"
          small
          class="ml-2"
          @click="PayTax()"
          :disabled="!selected.inprogress.length"
        >
          งานสำเร็จแล้ว
        </v-btn>
      </template>

      <template v-else-if="tab === 2">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          small
          class="ml-5"     
          @click="processCompleteCancel()"
          :disabled="!selected.complete.length"
        >
          ยกเลิก
        </v-btn>
        <v-btn
          color="success"
          small
          class="ml-2"
          @click="openCompleteForm()"
          :disabled="!selected.complete.length"
        >
          ลูกค้ารับแล้ว
        </v-btn>
      </template>
    </v-toolbar>

    <v-card>
      <v-tabs
        v-model="tab"
        align-with-title
        background-color="white"
        active-class="light black--text"
      >
        <v-tabs-slider color="light"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Pending
            ref="pending"
            :key="'pending_' + tab"
            @on-selected="selected.pending = $event"
            :search="search"
            :car-type-id="carTypeId"
          />
        </v-tab-item>
        <v-tab-item>
          <InProgress
            ref="inprogress"
            :key="'inprogress_' + tab"
            @on-selected="selected.inprogress = $event"
            :search="search"
            :car-type-id="carTypeId"
          />
        </v-tab-item>
        <v-tab-item>
          <Complete
            ref="complete"
            :key="'complete_' + tab"
            @on-selected="selected.complete = $event"
            :search="search"
          />
        </v-tab-item>
        <v-tab-item>
          <Received
            ref="received"
            :key="'receive_' + tab"
            @on-selected="selected.received = $event"
            :search="search"
          />
        </v-tab-item>
        <v-tab-item>
          <Cancel
            :key="'cancel_' + tab"
            :search="search"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog persistent scrollable max-width="900" v-model="dialogPendingForm">
      <PendingForm @onClose="closePendingDialog" />
    </v-dialog>

    <!-- <v-dialog
      persistent
      scrollable
      max-width="900"
      v-model="dialogCompleteForm"
    >
      <CompleteForm
        :item="dialogCompleteFormData"
        @onClose="dialogCompleteForm = false"
      />
    </v-dialog> -->
  </div>
</template>

<script>
import Pending from './Pending';
import InProgress from './InProgress';
import Complete from './Complete';
import Received from './Received';
import Cancel from './Cancel';
import PendingForm from './PendingForm';
import CompleteForm from './CompleteForm';
import * as moment from "moment";

export default {
  components: {
    Pending,
    InProgress,
    Complete,
    Received,
    Cancel,
    PendingForm,
    CompleteForm
  },
  data: () => ({
    dialogPendingForm: false,
    dialogCompleteForm: false,
    dialogCompleteFormData: null,
    search: '',
    carTypeId: 0,
    tab: 0,
    items: [
      'รอดำเนินการ',
      'กำลังดำเนินการ',
      'งานเสร็จสมบูรณ์ (รอลูกค้ามารับ)',
      'ลูกค้ารับไปแล้ว',
      'ยกเลิก'
    ],

    selected: {
      pending: [],
      inprogress: [],
      complete: [],
      received: []
    }
  }),

  created() {
    this.tab = this.$route.params.tab;
    this.$router.replace({ name: 'follow-transport' }).catch(() => {});
  },
  methods: {
    async processTrasportationJob() {
      this.loading = true;
      let process_array = this.$refs.pending.selected.map(x => {
        return x._id;
      });
      console.log(process_array)
      let body = {
        token: this.$jwt.sign(
          { process_array: process_array },
          this.$privateKey,
          { noTimestamp: true }
        )
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/transportation_job_on_process`, body)
        .then(res => {
          this.$refs.pending.selected = [];
          this.tab = 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async processPendingCancel(selected) {
      this.loading = true;
      let process_array = this.$refs.pending.selected.map(x => {
        return x._id;
      });
      let body = {
        token: this.$jwt.sign(
          { process_array: process_array },
          this.$privateKey,
          { noTimestamp: true }
        )
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/cancel_process`, body)
        .then(res => {
          this.$refs.pending.selected = [];
          this.tab = 4;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async processCompleteCancel(selected) {
      this.loading = true;
      let process_array = this.$refs.complete.selected.map(x => {
        return x._id;
      });
      let body = {
        token: this.$jwt.sign(
          { process_array: process_array },
          this.$privateKey,
          { noTimestamp: true }
        )
      };
      await this.$axios
        .post(`${this.$baseUrl}/process/cancel_process`, body)
        .then(res => {
          this.$refs.complete.selected = [];
          this.tab = 4;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    PayTax() {
      let process_array = this.$refs.inprogress.selected.map(x => {
        return {
          process_id: x._id,
          transportation_fee: x.transport_data.transportation_fee
        };
      });
      this.$router
        .push({
          name: 'follow-transport-paytax',
          params: { process_array: process_array }
        })
        .catch(() => {});
    },
    add() {
      this.dialogPendingFormData = null;
      this.dialogPendingForm = true;
    },
    async openCompleteForm() {
      let process_array = this.$refs.complete.selected.map(x => {
        return {
          _id: x._id,
          receiver_name: x.receiver_name,
        };
      });
      this.dialogCompleteFormData = {
        'process_id': process_array,
        'pick_up_date': moment().format("YYYY-MM-DD"),
        'pick_up_time': moment().format("HH:mm"),
      };
      let body = {
        token: this.$jwt.sign(this.dialogCompleteFormData, this.$privateKey, { noTimestamp: true }),
      };
      await this.$axios.post(`${this.$baseUrl}/process/record_transportation_parcel_book`, body)
        .then((res) =>{
          this.$refs.complete.selected = [];
          this.tab = 3;
        })
        .catch((err) => {
          console.log('err',err);
          this.$alertServerError({ title : err.error_message });
        });
      // this.dialogCompleteForm = true;
    },
    cancel() {},
    closePendingDialog() {
      this.dialogPendingForm = false;
      this.$router
        .go({ name: 'follow-transport', params: { tab: 0 } })
        .catch(() => {});
    }
  },
  watch: {
    tab: function(newVal, oldVal) {
      if (newVal) {
        this.search = '';
      }
    }
  }
};
</script>
